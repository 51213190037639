import React, { useEffect, useState } from "react";
import { Images } from "../Assets";
import { Colors, Helpers, isEmpty } from "../Utils";
import { Label, Image } from ".";
import { useDispatch, useSelector } from "react-redux";
import { FieldServices, PPPServices } from "../services";
import { toast } from "react-toastify";
import moment from "moment";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import Spinner from "./Spinner";
import { Object } from "ol";

function Dates() {
  let initialStatus = {
    previousStartDate: false,
    previousEndDate: false,
    nextStartDate: false,
    nextEndDate: false,
  };
  let initialObjHasDates = {
    previousStartDate: false,
    previousEndDate: false,
    nextStartDate: false,
    nextEndDate: false,
  };
  const appSettingsState = useSelector((state) => state.AppSettings);
  let dates = useSelector((state) => state.AppSettings.dates);
  const fieldsState = useSelector((state) => state.Fields);
  const insectState = useSelector((state) => state.Insects);
  let fieldUId = fieldsState.uId;
  let [datesStatus, setDatesStatus] = useState(initialStatus);
  let [hasDates, setHasDates] = useState(initialObjHasDates);
  let [dateType, setDateType] = useState("Next");
  let [isModalOpen, setIsModalOpen] = useState(false);
  let [isLoading, setIsLoading] = useState(false);
  let [isAllLoading, setIsAllLoading] = useState(false);
  const dispatch = useDispatch();

  const setDateStatus = (date, type, field) => {
    FieldServices.checkDateOfAField(fieldUId, date, type)
      .then((res) => {
        if (res.data.date.length > 0) {
          setDatesStatus((status) => {
            return { ...status, [field]: true };
          });
        } else {
          setDatesStatus((status) => {
            return { ...status, [field]: false };
          });
        }
      })
      .catch((err) => {
        setDatesStatus((status) => {
          return { ...status, [field]: false };
        });
      });
  };

  const checkHasDate = (date, type, field, operator) => {
    FieldServices.checkHasDate(fieldUId, date, type, operator)
      .then((res) => {
        if (res.data.date[0][type] !== null) {
          setHasDates((status) => {
            return { ...status, [field]: true };
          });
        } else {
          setHasDates((status) => {
            return { ...status, [field]: false };
          });
        }
      })
      .catch((err) => {
        setHasDates((status) => {
          return { ...status, [field]: false };
        });
      });
  };

  useEffect(() => {
    setDatesStatus(initialStatus);
    if (insectState.selected && appSettingsState.selectedPage !== "PPP") {
      if (!isEmpty(dates.nextDate.startDate)) {
        setDateStatus(dates.nextDate.startDate, "start_date", "nextStartDate");
      }
      if (!isEmpty(dates.nextDate.endDate)) {
        setDateStatus(dates.nextDate.endDate, "end_date", "nextEndDate");
      }
      if (!isEmpty(dates.nextDate.startDate)) {
        checkHasDate(
          dates.nextDate.startDate,
          "start_date",
          "nextStartDate",
          "<"
        );
      }
      if (!isEmpty(dates.nextDate.endDate)) {
        checkHasDate(dates.nextDate.endDate, "end_date", "nextEndDate", ">");
      }
      if (appSettingsState.selectedMode !== "single") {
        if (!isEmpty(dates.previousDate.startDate)) {
          setDateStatus(
            dates.previousDate.startDate,
            "start_date",
            "previousStartDate"
          );
        }
        if (!isEmpty(dates.previousDate.endDate)) {
          setDateStatus(
            dates.previousDate.endDate,
            "end_date",
            "previousEndDate"
          );
        }
        if (!isEmpty(dates.previousDate.startDate)) {
          checkHasDate(
            dates.previousDate.startDate,
            "start_date",
            "previousStartDate",
            "<"
          );
        }
        if (!isEmpty(dates.previousDate.endDate)) {
          checkHasDate(
            dates.previousDate.endDate,
            "end_date",
            "previousEndDate",
            ">"
          );
        }
      }
    }
  }, [dates]);

  const seasonDate = async () => {
    if(fieldsState.uId){
      const result = await FieldServices.getSeasonDate(fieldsState.uId);

      var startDate = result.data.data
        ? moment(result.data.data.start_date).format("YYYY-MM-DD")
        : moment().format("YYYY-MM-DD");

        var endDate = '';
      if(!isEmpty(insectState.selectedDisease)){
        const dateResult = await FieldServices.getPlantEntryDate(fieldsState.uId, insectState.selectedDisease.value);

        endDate = dateResult.data
          ? moment(dateResult.data.date).format("YYYY-MM-DD")
          : moment().format("YYYY-MM-DD");
        console.log(endDate);
      }else{
        endDate = moment().format("YYYY-MM-DD");
      }
      
      dispatch.AppSettings.SetNextDates({
        startDate,
        endDate,
      });
    }
  };

  const getDates = (startDate, endDate, operation, next = true) => {
    FieldServices.getDatesOfAField(
      fieldsState.uId,
      startDate,
      endDate,
      operation
    ).then((res) => {
      let startDate = res.data.start_date;
      let endDate = res.data.end_date;
      if (next)
        dispatch.AppSettings.SetNextDates({
          startDate,
          endDate,
        });
      else
        dispatch.AppSettings.SetPreviousDates({
          startDate,
          endDate,
        });
      if (!isEmpty(startDate)) {
        if (next) {
          setDatesStatus({ ...datesStatus, nextStartDate: true });
        } else {
          setDatesStatus({ ...datesStatus, previousStartDate: true });
        }
      }
      if (!isEmpty(endDate)) {
        if (next) {
          setDatesStatus({ ...datesStatus, nextEndDate: true });
        } else {
          setDatesStatus({ ...datesStatus, previousEndDate: true });
        }
      }
    });
  };

  const getDate = (
    currentDate,
    dateType,
    operation,
    start = true,
    next = false
  ) => {
    FieldServices.getDateOfAField(
      fieldsState.uId,
      currentDate,
      dateType,
      operation
    ).then((res) => {
      if (start) {
        if (next) {
          dispatch.AppSettings.SetNextDates({
            startDate: res.data.date,
            endDate: appSettingsState.dates.nextDate.endDate,
          });
        } else {
          dispatch.AppSettings.SetPreviousDates({
            startDate: res.data.date,
            endDate: appSettingsState.dates.previousDate.endDate,
          });
        }
      } else {
        if (next) {
          dispatch.AppSettings.SetNextDates({
            startDate: appSettingsState.dates.nextDate.startDate,
            endDate: res.data.date,
          });
        } else {
          dispatch.AppSettings.SetPreviousDates({
            startDate: appSettingsState.dates.previousDate.startDate,
            endDate: res.data.date,
          });
        }
      }
    });
  };

  const getLatestDatesOfAField = () => {
    FieldServices.getLatestDatesOfAField(fieldUId)
      .then((res) => {
        let startDate = res.data.start_date;
        let endDate = res.data.end_date;
        dispatch.AppSettings.SetNextDates({
          startDate,
          endDate,
        });
      })
      .catch((err) => {
        toast.error("Error getting dates!");
      });
  };

  const areDateRangesOverlapping = () => {
    let previousStartDate = moment(
      appSettingsState.dates.previousDate.startDate
    );
    let previousEndDate = moment(appSettingsState.dates.previousDate.endDate);
    let nextStartDate = moment(appSettingsState.dates.nextDate.startDate);
    let nextEndDate = moment(appSettingsState.dates.nextDate.endDate);
    // if (previousStartDate <= nextStartDate && nextStartDate <= previousEndDate)
    //   return true;
    // if (previousStartDate <= nextEndDate && nextEndDate <= previousEndDate)
    //   return true;
    // if (nextStartDate < previousStartDate && previousEndDate < nextEndDate)
    //   return true;
    // return false;

    if (
      previousStartDate > nextStartDate ||
      previousStartDate > nextEndDate ||
      previousEndDate > nextEndDate
    ) {
      return true;
    }
    return false;
  };

  const areDatesValid = () => {
    if(insectState.selectedDisease){
      return true;
    }
    let previousStartDate = appSettingsState.dates.previousDate.startDate;
    let previousEndDate = appSettingsState.dates.previousDate.endDate;
    let nextStartDate = appSettingsState.dates.nextDate.startDate;
    let nextEndDate = appSettingsState.dates.nextDate.endDate;
    let proceed = false;
    if (isEmpty(nextStartDate)) {
      toast.error("Please select Next Start Date!");
    } else if (isEmpty(nextEndDate)) {
      toast.error("Please select Next End Date!");
    } else {
      if (appSettingsState.selectedMode !== "single") {
        if (isEmpty(previousStartDate)) {
          toast.error("Please select Previous Start Date!");
        } else if (isEmpty(previousEndDate)) {
          toast.error("Please select Previous End Date!");
        } else {
          proceed = true;
        }
      } else {
        proceed = true;
      }
    }
    return proceed;
  };

  const getData = (all = false) => {
    dispatch.AppSettings.SetIntialLoad(false);
    if (areDatesValid()) {
      if (appSettingsState.selectedMode !== "single") {
        if (areDateRangesOverlapping()) {
          toast.error("Previous dates must be smaller than next dates!");
          return;
        }
      }
      if (appSettingsState.selectedPage === "Home") {
        try {
          getHomeData(all);
        } catch (e) {
          setIsLoading(false);
        }
      } else if (appSettingsState.selectedPage === "PPP") {
        try {
          getPPPData();
        } catch (e) {
          setIsLoading(false);
        }
      } else {
        setIsLoading(true);
        FieldServices.getStatsOfLocationInAField(
          fieldsState.uId,
          dates.nextDate.startDate,
          dates.nextDate.endDate,
          dates.previousDate.startDate,
          dates.previousDate.endDate,
          insectState.selected,
          insectState.selectedDisease,
          appSettingsState.selectedMode
        )
          .then(async (res) => {
            console.log('here it comes');
            var nextData = res.data.data.map((obj) => {
              var sum = insectState.selected
                ? parseFloat(obj.insects[0].sum)
                : obj.sum;
              var avg = insectState.selected
                ? parseFloat(obj.insects[0].avg)
                : 0;
              var changeRate = insectState.selected
                ? parseFloat(obj.insects[0].changeRate)
                : getPlantRate(obj.field, sum);

              return {
                id: parseInt(obj.id),
                location_no: obj.location_no,
                title: obj.title,
                sum: sum,
                avg: avg,
                changeRate: changeRate,
                latLng: obj.latLng ?? obj.geom,
                timelineData: obj.timelineData ?? [],
              };
            });
            setIsLoading(false);
            dispatch.Fields.SetFieldDataName(fieldsState.fieldName);
            if (appSettingsState.selectedMode !== "single") {
              setIsLoading(true);
              await FieldServices.getStatsOfLocationInAField(
                fieldsState.uId,
                dates.previousDate.startDate,
                dates.previousDate.endDate,
                dates.previousDate.startDate,
                dates.previousDate.endDate,
                insectState.selected,
                insectState.selectedDisease,
                "single"
              )
                .then(async (res) => {
                  var prevData = res.data.data.map((obj) => {
                    var sum = insectState.selected
                      ? parseFloat(obj.insects[0].sum)
                      : obj.sum;
                    var avg = insectState.selected
                      ? parseFloat(obj.insects[0].avg)
                      : 0;
                    var changeRate = insectState.selected
                      ? parseFloat(obj.insects[0].changeRate)
                      : getPlantRate(obj.field, sum);
                    return {
                      id: parseInt(obj.id),
                      location_no: obj.location_no,
                      title: obj.title,
                      sum: sum,
                      avg: avg,
                      changeRate: changeRate,
                      latLng: obj.latLng ?? obj.geom,
                      timelineData: obj.timelineData ?? [],
                    };
                  });
                  var returnedValues = CheckValues(prevData, nextData);
                  nextData = returnedValues.modifiedNextData;
                  prevData = returnedValues.modifiedPrevData;
                  await prevData.sort(function (a, b) {
                    return a.location_no - b.location_no;
                  });
                  await dispatch.Fields.AddLocationsData({
                    type: "dual",
                    data: prevData,
                    eraseDate: true,
                  });
                  setIsLoading(false);
                })
                .catch((err) => {
                  setIsLoading(false);
                });
            }
            await nextData.sort(function (a, b) {
              return a.location_no - b.location_no;
            });
            await dispatch.Fields.AddLocationsData({
              type: "single",
              eraseDate: true,
              data: nextData,
            });
          })
          .catch((err) => {
            setIsLoading(false);
          });
        getLineChart(
          appSettingsState.selectedChartMode,
          fieldsState.uId,
          insectState.selected
        );
      }
    }
  };

  const getLineChart = async (selectedChartMode, field, insect) => {
    const lineData = await Helpers.getLineChartData(
      selectedChartMode,
      field,
      insect
    );
    // dispatch.Fields.SetChartData({ type: "single", data: lineData.nextData });
    // if (appSettingsState.selectedMode !== "single")
    dispatch.Fields.SetChartData({
      type: "dual",
      data: lineData.previousData,
    });
    dispatch.PPP.SetChartData(lineData.pppData);
  };

  const CheckValues = (prevData, nextData) => {
    let nextMin = Math.min.apply(
      null,
      nextData.map(function (o) {
        return o.location_no;
      })
    );
    let nextMax = Math.max.apply(
      null,
      nextData.map(function (o) {
        return o.location_no;
      })
    );
    let prevMin = Math.min.apply(
      null,
      prevData.map(function (o) {
        return o.location_no;
      })
    );
    let prevMax = Math.max.apply(
      null,
      prevData.map(function (o) {
        return o.location_no;
      })
    );
    let min = nextMin > prevMin ? prevMin : nextMin;
    let max = nextMax > prevMax ? nextMax : prevMax;

    let modifiedNextData = insertValues(min, max, nextData, prevData);
    let modifiedPrevData = insertValues(min, max, prevData, nextData);
    return { modifiedNextData, modifiedPrevData };
  };

  const insertValues = (min, max, first, second) => {
    for (let i = min; i <= max; i++) {
      let index = first.findIndex((d) => d.location_no === i);
      if (index === -1) {
        let otherIndex = second.findIndex((d) => d.location_no === i);
        if (otherIndex !== -1) {
          let obj = second[otherIndex];
          first.push({
            id: obj.id,
            location_no: obj.location_no,
            sum: 0,
            avg: 0,
            changeRate: -100,
            title: obj.title,
            timelineData: obj.timelineData,
          });
        }
      }
    }
    return first;
  };

  const getHomeData = async (all) => {
    dispatch.AppSettings.SetIntialLoad(false);
    let fields = fieldsState.fields;
    try {
      if (all) {
        setIsAllLoading(true);
        for (var i = 0; i < fields.length; i++) {
          await getChangeRateOfAField(fields[i].uid);
        }
        setIsAllLoading(false);
      } else {
        setIsLoading(true);
        await getChangeRateOfAField(fieldsState.uId);
        setIsLoading(false);
      }
    } catch (e) {
      if (!all) await setFieldsData(fieldsState.uId, null, null);
      else {
        for (var i = 0; i < fields.length; i++) {
          await setFieldsData(fields[i].uid, null, null);
        }
      }
      setIsLoading(false);
    }
  };

  const getPPPData = async () => {
    setIsLoading(true);
    PPPServices.getFieldTreatments(
      fieldsState.uId,
      dates.nextDate.startDate,
      dates.nextDate.endDate,
      dates.previousDate.startDate,
      dates.previousDate.endDate,
      appSettingsState.selectedMode
    ).then(async (res) => {
      console.log(res);
      setIsLoading(false);
      dispatch.PPP.SetTreatments(res.data.data);
      dispatch.PPP.SetSelectedTreatment(null);
    }).catch((err) => {
      setIsLoading(false);
    });;
  };

  const getChangeRateOfAField = async (uId) => {
    let previousStartDate = appSettingsState.dates.previousDate.startDate;
    let previousEndDate = appSettingsState.dates.previousDate.endDate;
    let nextStartDate = appSettingsState.dates.nextDate.startDate;
    let nextEndDate = appSettingsState.dates.nextDate.endDate;
    if (insectState.selected) {
      let resNext = await FieldServices.getDateRangesData(
        uId,
        nextStartDate,
        nextEndDate,
        insectState.selected
      );
      let resPrev = null;
      if (appSettingsState.selectedMode !== "single") {
        resPrev = await FieldServices.getDateRangesData(
          uId,
          previousStartDate,
          previousEndDate,
          insectState.selected
        );
      } else {
        let dates = await getPrevDatesOfAField(nextStartDate, nextEndDate);
        if (
          !isEmpty(dates.data) &&
          dates.data.start_date &&
          dates.data.end_date
        ) {
          previousStartDate = dates.data.start_date;
          previousEndDate = dates.data.end_date;
          resPrev = await FieldServices.getDateRangesData(
            uId,
            previousStartDate,
            previousEndDate,
            insectState.selected
          );
        } else {
          resPrev = {
            data: null,
          };
        }
      }
      let changeRate = 0;

      let nextChangeRate = getAvgOfDateRange(
        resNext.data,
        moment(nextStartDate),
        moment(nextEndDate)
      );
      let count = nextChangeRate;
      let previousChangeRate = 0;

      if (!isEmpty(resPrev.data)) {
        previousChangeRate = getAvgOfDateRange(
          resPrev.data,
          moment(previousStartDate),
          moment(previousEndDate)
        );
      }

      if (isEmpty(resNext.data) && !isEmpty(resPrev.data)) {
        changeRate = -100;
      } else if (!isEmpty(resNext.data) && isEmpty(resPrev.data)) {
        changeRate = 100;
      } else if (!isEmpty(resNext.data) && !isEmpty(resPrev.data)) {
        let increase = previousChangeRate - nextChangeRate;
        changeRate = (increase / previousChangeRate) * 100;
        changeRate = changeRate ? changeRate : 0;
      }
      await setFieldsData(uId, changeRate, count);
    } else {
      let response = await FieldServices.getPlantCount(
        uId,
        nextStartDate,
        nextEndDate,
        insectState.selectedDisease.value
      );

      await setFieldsData(uId, 0, response?.data?.data || 0);
    }
  };

  const setFieldsData = async (uId, changeRate, count) => {
    let data = fieldsState.fieldsData;
    let index = data.findIndex((data) => data.uId === uId);
    let obj = null;
    if (index >= 0) {
      obj = { ...data[index], changeRate, count };
    } else {
      obj = { uId, changeRate, count };
    }
    if (uId === fieldsState.uId) {
      await dispatch.Fields.setChangeRate(changeRate);
    }
    await dispatch.Fields.addFieldData(obj);
  };

  const getAvgOfDateRange = (data, startDate, endDate) => {
    let avgTotal = 0;
    for (var i = 0; i < data.length; i++) {
      let _startDate = moment(data[i].startDate);
      let _endDate = moment(data[i].endDate);
      let daysInRange = _endDate.diff(_startDate, "days");
      daysInRange++;
      let rangeAvg = data[i].sum / daysInRange;
      let days = getOverlappingDaysInTwoDateRanges(
        startDate,
        endDate,
        _startDate,
        _endDate,
        daysInRange
      );
      avgTotal += days * rangeAvg;
    }
    return avgTotal;
  };

  const getPlantRate = (field, sum) => {
    var avg = 0;
    if (field) {
      avg = sum / (field.rows_per_cage * field.plants_per_cage);
      avg *= 100;
      avg = avg.toFixed(2);
    }

    return avg;
  };

  const getOverlappingDaysInTwoDateRanges = (
    startDate,
    endDate,
    _startDate,
    _endDate,
    daysInRange
  ) => {
    let diffDays = 0;
    if (_startDate >= startDate && _endDate > endDate) {
      diffDays = _endDate.diff(endDate, "days");
      return daysInRange - diffDays;
    } else if (_startDate < startDate && _endDate <= endDate) {
      diffDays = startDate.diff(_startDate, "days");
      return daysInRange - diffDays;
    } else if (_startDate < startDate && _endDate > endDate) {
      return endDate.diff(startDate, "days");
    } else {
      return daysInRange;
    }
  };

  const getPrevDatesOfAField = async (startDate, endDate) => {
    let resDates = await FieldServices.getDatesOfAFieldAsync(
      fieldsState.uId,
      startDate,
      endDate,
      "<"
    );
    return resDates;
  };

  const Reset = () => {
    dispatch.AppSettings.SelectMode("single");
    dispatch.AppSettings.SetPreviousDates({
      startDate: "",
      endDate: "",
    });
    if (insectState.selectedDisease || appSettingsState.selectedPage === "PPP") {
      seasonDate();
    } else {
      getLatestDatesOfAField();
    }
  };

  const handleSelect = (ranges) => {
    let startDate = moment(ranges.selection.startDate).format("yyyy-MM-DD");
    let endDate = moment(ranges.selection.endDate).format("yyyy-MM-DD");
    if (dateType === "Next") {
      dispatch.AppSettings.SetNextDates({
        startDate,
        endDate,
      });
    } else {
      dispatch.AppSettings.SetPreviousDates({
        startDate,
        endDate,
      });
    }
  };

  const [isCount, setCount] = useState(false);

  useEffect(() => {
    if (
      fieldsState.uId &&
      appSettingsState.dates.nextDate.startDate &&
      appSettingsState.dates.nextDate.endDate &&
      !isCount
    ) {
      findData();
    }
  }, [fieldsState.uId, appSettingsState.dates.nextDate]);

  const findData = async () => {
    await getChangeRateOfAField(fieldsState.uId);
    setCount(true);
  };

  console.log('dates', appSettingsState?.dates);

  return (
    <>
      <Dialog
        onClose={() => {
          setIsModalOpen((state) => !state);
        }}
        open={isModalOpen}
        maxWidth={"lg"}
      >
        <DialogTitle>Select {dateType} Date</DialogTitle>
        <DateRangePicker
          ranges={[
            {
              startDate: new Date(
                dateType === "Next"
                  ? appSettingsState.dates.nextDate.startDate
                  : appSettingsState.dates.previousDate.startDate
              ),
              endDate: new Date(
                dateType === "Next"
                  ? appSettingsState.dates.nextDate.endDate
                  : appSettingsState.dates.previousDate.endDate
              ),
              key: "selection",
            },
          ]}
          onChange={(ranges) => {
            handleSelect(ranges);
          }}
          highlightDates={[new Date(`10/10/2019`).getDate()]}
          months={2}
          direction="horizontal"
          showSelectionPreview={true}
          moveRangeOnFirstSelection={false}
        />
      </Dialog>
      <div
        className=" mt-2 mr-2 mb-2 p-3 rounded-md"
        style={{ background: Colors.PRIMARY }}
      >
        <div className="flex flex-wrap">
          <div className="w-5/12">
            {appSettingsState.selectedMode !== "single" ? (
              <div className="flex flex-wrap">
                <div className="w-1/12">
                  <div className="flex flex-wrap">
                    {hasDates.previousStartDate && (
                      <div
                        className="w-6/12 cursor-pointer"
                        onClick={() =>
                          getDate(
                            appSettingsState?.dates?.previousDate?.startDate,
                            "start_date",
                            "<",
                            true
                          )
                        }
                      >
                        <Image
                          height="40px"
                          width="25px"
                          src={Images.yellow_arrow_left}
                          alt="previous_date"
                        />
                      </div>
                    )}
                    {hasDates.previousStartDate && (
                      <div
                        className="w-6/12 cursor-pointer"
                        onClick={() => {
                          if (appSettingsState.selectedMode !== "single")
                            getDates(
                              appSettingsState?.dates?.previousDate?.startDate,
                              appSettingsState?.dates?.previousDate?.endDate,
                              "<",
                              false
                            );
                        }}
                      >
                        <Image
                          height="40px"
                          width="25px"
                          src={Images.yellow_arrow_left}
                          alt="previous_date"
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div
                  className="w-5/12 cursor-pointer"
                  onClick={() => {
                    setIsModalOpen(true);
                    setDateType("Previous");
                  }}
                >
                  <Label text="Start Date:" />
                  <br />
                  {datesStatus.previousStartDate && (
                    <Label text="[" size="text-2xl" error={true} />
                  )}

                  <Label
                    text={moment(
                      appSettingsState?.dates?.previousDate?.startDate
                    ).format("DD-MMM-yyyy")}
                    size="text-2xl"
                  />
                </div>
                <div
                  className="w-5/12"
                  onClick={() => {
                    setIsModalOpen(true);
                    setDateType("Previous");
                  }}
                >
                  <Label text="End Date" />
                  <br />
                  <Label
                    text={moment(
                      appSettingsState?.dates?.previousDate?.endDate
                    ).format("DD-MMM-yyyy")}
                    size="text-2xl"
                  />

                  {datesStatus.previousEndDate && (
                    <Label text="]" size="text-2xl" error={true} />
                  )}
                </div>
                <div className="w-1/12">
                  <div className="flex flex-wrap cursor-pointer">
                    {hasDates.previousEndDate && (
                      <div
                        className="w-6/12"
                        onClick={() => {
                          if (appSettingsState.selectedMode !== "single")
                            getDates(
                              appSettingsState?.dates?.previousDate?.startDate,
                              appSettingsState?.dates?.previousDate?.endDate,
                              ">",
                              false
                            );
                        }}
                      >
                        <Image
                          height="40px"
                          width="25px"
                          src={Images.yellow_arrow_right}
                          alt="previous_date"
                        />
                      </div>
                    )}
                    {hasDates.previousEndDate && (
                      <div
                        className="w-6/12 cursor-pointer"
                        onClick={() =>
                          getDate(
                            appSettingsState?.dates?.previousDate?.endDate,
                            "end_date",
                            ">",
                            false
                          )
                        }
                      >
                        <Image
                          height="40px"
                          width="25px"
                          src={Images.yellow_arrow_right}
                          alt="previous_date"
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <Label
                text="Select dual mode for this operation."
                size="text-xl"
              />
            )}
          </div>
          <div className="w-2/12">
            <div className="flex flex-wrap">
              <div className="w-10/12">
                <Label text="Current:" />
                <br />
                <Label text={fieldsState?.fieldName} size="text-2xl" />
              </div>
              <div className="w-2/12 flex">
                {!appSettingsState.initialLoad &&
                  fieldsState?.changeRate !== 0 && (
                    <Image
                      src={
                        fieldsState?.changeRate > 0
                          ? Images.green_arrow
                          : Images.red_arrow
                      }
                      alt="Progress"
                      height="40px"
                    />
                  )}
              </div>
            </div>
          </div>
          <div className="w-5/12">
            <div className="flex flex-wrap">
              <div className="w-1/12">
                <div className="flex flex-wrap mr-2">
                  {!appSettingsState.initialLoad && hasDates.nextStartDate && (
                    <>
                      <div
                        className="w-6/12 cursor-pointer"
                        onClick={() =>
                          getDate(
                            appSettingsState?.dates?.nextDate?.startDate,
                            "start_date",
                            "<",
                            true,
                            true
                          )
                        }
                      >
                        <Image
                          src={Images.yellow_arrow_left}
                          height="40px"
                          width="25px"
                          alt="previous_date_current"
                        />
                      </div>
                      <div
                        className="w-6/12 cursor-pointer"
                        onClick={() =>
                          getDates(
                            appSettingsState?.dates?.nextDate?.startDate,
                            appSettingsState?.dates?.nextDate?.endDate,
                            "<",
                            true
                          )
                        }
                      >
                        <Image
                          src={Images.yellow_arrow_left}
                          height="40px"
                          width="25px"
                          alt="previous_date_current"
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>

              <div
                className="w-5/12"
                onClick={() => {
                  setIsModalOpen(true);
                  setDateType("Next");
                }}
              >
                <Label text="Start Date:" />
                <br />

                {datesStatus.nextStartDate && (
                  <Label text="[" size="text-2xl" error={true} />
                )}
                <Label
                  text={
                    appSettingsState?.dates?.nextDate?.startDate !== ""
                      ? moment(
                          appSettingsState?.dates?.nextDate?.startDate
                        ).format("DD-MMM-yyyy")
                      : ""
                  }
                  size="text-2xl"
                />
              </div>
              <div
                className="w-5/12"
                onClick={() => {
                  setIsModalOpen(true);
                  setDateType("Next");
                }}
              >
                <Label text="End Date" />
                <br />
                <Label
                  text={
                    appSettingsState?.dates?.nextDate?.endDate !== ""
                      ? moment(
                          appSettingsState?.dates?.nextDate?.endDate
                        ).format("DD-MMM-yyyy")
                      : ""
                  }
                  size="text-2xl"
                />
                {datesStatus.nextEndDate && (
                  <Label text="]" size="text-2xl" error={true} />
                )}
              </div>
              <div className="w-1/12">
                <div className="flex flex-wrap">
                  {hasDates.nextEndDate && (
                    <div
                      className="w-6/12 cursor-pointer"
                      onClick={() =>
                        getDates(
                          appSettingsState?.dates?.nextDate?.startDate,
                          appSettingsState?.dates?.nextDate?.endDate,
                          ">",
                          true
                        )
                      }
                    >
                      <Image
                        src={Images.yellow_arrow_right}
                        height="40px"
                        width="25px"
                        alt="next_date_current"
                      />
                    </div>
                  )}
                  {hasDates.nextEndDate && (
                    <div
                      className="w-6/12 cursor-pointer"
                      onClick={() =>
                        getDate(
                          appSettingsState?.dates?.nextDate?.endDate,
                          "end_date",
                          ">",
                          false,
                          true
                        )
                      }
                    >
                      <Image
                        src={Images.yellow_arrow_right}
                        height="40px"
                        width="25px"
                        alt="next_date_current"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap  mt-1">
          <button
            className="bg-transparent border-2 rounded-md mr-2 p-2 border-primary text-sm"
            onClick={() => getData(false)}
          >
            {isLoading ? (
              <Spinner color="#fff" size={20} className="mx-5" />
            ) : (
              "Apply"
            )}
          </button>
          {window.location.href.indexOf("/maps") > -1 ? (
            <button
              className="bg-transparent border-2 rounded-md mr-2 p-2 border-primary text-sm"
              onClick={() => getData(false)}
            >
              {isLoading ? (
                <Spinner color="#fff" size={20} className="mx-5" />
              ) : (
                "Apply with zoom"
              )}
            </button>
          ) : (
            ""
          )}
          <button
            className="bg-transparent border-2 rounded-md mr-2 p-2 border-primary text-sm"
            onClick={() => getData(true)}
          >
            {isAllLoading ? (
              <Spinner color="#fff" size={20} className="mx-5" />
            ) : (
              "Apply All"
            )}
          </button>
          <button
            className="bg-transparent border-2 rounded-md mr-2 p-2 border-primary text-sm"
            onClick={Reset}
          >
            Reset
          </button>
        </div>
      </div>
    </>
  );
}

export default Dates;
